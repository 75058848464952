// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
//

// jshint esversion:6

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['date', 'time']

  connect() {
    this.setDate();
  }

  setDate() {
    const restaurant_id = this.data.get('restaurantId')
    const date = this.dateTarget.value
    if (date){
      this.setTimeElementBlank()
      this.getHours(restaurant_id, date)
    } else {
      this.timeTarget.disabled = true
      alert('Please Select a Date')
    }
  }

  refactorHours(array) {
    array.map(element => {
      const option = document.createElement('option');
      option.text = element
      option.value = element
      this.timeTarget.appendChild(option);
    });
  }

  getHours(restaurant_id, date) {
    const path = '/api/partners/' + restaurant_id +'/work_hours/' + date
    Rails.ajax({
      url: path,
      type: 'get',
      success: (data) => {
        if (!Array.isArray(data) || !data.length) {
          this.timeTarget.disabled = true
          alert('Have No Result! Please select different date.')
        } else {
          this.refactorHours(data)
          this.timeTarget.disabled = false
        }
      },
      error: (error) => {
        alert(`Something went wrong! ${error}`)
      }
    });
  }

  /////////////////////
  // Private methods //
  /////////////////////

  setTimeElementBlank() {
    this.timeTarget.innerHTML = ''
  }
}
