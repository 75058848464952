// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
//

// jshint esversion:6

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cuisine', 'restaurantsContainer']

  handleForCuisine () {
    const selectedCuisines = [];
    this.cuisineTargets.forEach((element) => {
      if (element.checked === true) {
        selectedCuisines.push(element.dataset.cuisineId);
      }
    });
    location = this.data.get('url') +'&cuisine_ids=' +selectedCuisines;
  }
}
