// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//

// jshint esversion:6

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['dateItem', 'timeItem'];

  connect() {
    document.addEventListener('DOMContentLoaded', () => {
      this.disablePaymentButton(this.dateItemTarget.value, this.timeItemTarget.value);
    });
  }

  calculateDate() {
    setTimeout(() => {
      this.disablePaymentButton(this.dateItemTarget.value, this.timeItemTarget.value);
    }, 250);
  }

  disablePaymentButton(dateItemValue, timeItemValue) {
    const $ = document.querySelector.bind(document);
    if ((dateItemValue && timeItemValue) !== '') {
      $('.js-btn-paynow').classList.remove('disabled');
      $('.js-btn-paynow').disabled = false;
    } else {
      $('.js-btn-paynow').disabled = true;
      $('.js-btn-paynow').classList.add('disabled');
    }
  }
  
}